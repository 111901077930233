.mainHome{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
.mainHome ul{
    list-style-type: none;
}
.mainHome ul li{
    padding: 10px;
    background-color: beige;
    margin: 20px;
}

.mainHome ul li a{
    color: black;
    text-decoration: none;
    font-size: 20px;
}